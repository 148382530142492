.scoreboard {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    text-align: center;    

    .player-row {
        td {
            padding-top: 5px;
        }
    }

    .name-header {
        width: 30%;
    }
}