.summary-table {
    margin-top: 20px;

    tr {
        cursor: pointer;

        &:hover {
            background-color: rgba(255, 255, 255, 0.75);
        }
    }

    tbody {
        td {
            height: 100px;
            vertical-align: middle;
            text-align: center;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 20px;

            .terrorist {
                color: #b79e46;
            }

            .counter-terrorist {
                color: #6aa8d6;
            }
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: fill;
        }
    }

    thead {
        text-align: center;

        .map-col {
            max-width: 10%;
        }

        .event-col {
            max-width: 10%;
        }

        .teams-col {
            width: 50%;
        }

        .score-col {
            width: 30%;
        }
    }

}