.game-details-wrapper {
    .details-header {
        margin-top: 10px;
        background-color: #f5f5f5;
        font-size: 20px;
        font-weight: 700;

        .download-button {
            width: 20px;
            height: 20px;
            position: absolute;
            right: 0;
            margin: 5px;
            cursor: pointer;
        }

        .map {
            padding-left: 0px;

            .map-name-bottom {
                vertical-align: middle;
                text-align: center;
                bottom: 0;
                position: absolute;
                left: 15px;
                display: inline-block;
                color: white;
                background-color: rgba(0, 0, 0, 0.5)
            }

            .map-name-middle {
                text-align: center;
            }

            img {
                height: 100%;
                width: 100%;
            }
        }

        .title {
            text-align: center;
            text-transform: uppercase;

            .terrorist {
                color: #b79e46;
            }

            .counter-terrorist {
                color: #6aa8d6;
            }
        }
    }

    .scoreboards-wrapper {

        .scoreboard {
            margin-bottom: 3/0px;
        }
    }
}