.App {
  background-color: #f0f0f0;
  font-family: 'Rajdhani', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

}

.content-box {
  background-color: white;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.1)
}

.content {
  padding-top: 20px;
}