.pagination-bar {

    margin: auto;

    .pagination-button {
        padding: 5px;
        cursor: pointer;
        color: #BABDD3;

        &.selected {
            color: black;
        }

        &:hover {
            color: black;
        }
    }
}