#header {
    background-color: #4b5b6e;
    height: 125px;

    #site-logo {
        padding:0px;
        margin:0px;
        img {
            background-color: #4b5b6e;
            width: 480px;
            height: 120px;
        }

        cursor: pointer;
    }
}
